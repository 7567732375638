import { i18n } from "@/plugins/language";
import { DateTime } from "luxon";

import { getTimeObject } from "@/utils/time/getTimeObject";

const isEarly = (timeStamp, currentTime) => {
  return timeStamp <= currentTime;
};

const getHandledValue = (num) => {
  return num < 10 ? "0" + num : num;
};

const getDate = (timeStamp, startType) => {
  const d = new Date(timeStamp);
  const year = d.getFullYear();
  const month = getHandledValue(d.getMonth() + 1);
  const date = getHandledValue(d.getDate());
  const hours = getHandledValue(d.getHours());
  const minutes = getHandledValue(d.getMinutes());
  const second = getHandledValue(d.getSeconds());
  let resStr = "";
  if (startType === "year") resStr = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + second;
  else resStr = month + "-" + date + " " + hours + ":" + minutes;
  return resStr;
};

const getRelativeTime = (reportDate) => {
  let timeStamp = new Date(reportDate).getTime();
  const currentTime = getTimeObject().offsetGetTime();

  const IS_EARLY = isEarly(timeStamp, currentTime);
  let diff = currentTime - timeStamp;
  if (!IS_EARLY) diff = -diff;
  let resStr = "";
  let dirStr = IS_EARLY ? i18n.t("reports.table.iview.time.before") : i18n.t("reports.table.iview.time.after");
  if (diff < 1000) resStr = i18n.t("reports.table.iview.time.just");
  else if (diff < 60000) resStr = parseInt(diff / 1000) + i18n.t("reports.table.iview.time.seconds") + dirStr;
  else if (diff >= 60000 && diff < 3600000)
    resStr = Math.floor(diff / 60000) + i18n.t("reports.table.iview.time.minutes") + dirStr;
  else if (diff >= 3600000 && diff < 86400000)
    resStr = Math.floor(diff / 3600000) + i18n.t("reports.table.iview.time.hours") + dirStr;
  else if (diff >= 86400000 && diff < 2623860000)
    resStr = Math.floor(diff / 86400000) + i18n.t("reports.table.iview.time.days") + dirStr;
  else if (diff >= 2623860000 && diff <= 31567860000 && IS_EARLY) resStr = getDate(timeStamp);
  else resStr = getDate(timeStamp, "year");
  return resStr;
};

export default getRelativeTime;

export const isSameDay = (date1, date2) => {
  if (date1) {
    date1 = DateTime.fromJSDate(date1);
  }

  if (date2) {
    date2 = DateTime.fromJSDate(date2);
  }
  return date1?.year === date2?.year && date1?.month === date2?.month && date1?.day === date2?.day;
};

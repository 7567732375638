import { padStart } from "lodash";
import { DateTime, Duration } from "luxon";

export function formatSecondsToHMS({ seconds, showSeconds = true }) {
  return `${seconds >= 3600 ? Math.floor(seconds / 3600) + "h " : ""}${
    seconds > 60 || !showSeconds ? Math.floor((seconds % 3600) / 60) + "m " : ""
  } ${showSeconds ? (seconds % 60) + "s" : ""}`;
}

export function formatSecondsToHHMMSS({ seconds }) {
  return Duration.fromObject({ seconds }).toFormat("hh'h' mm'm' ss's'");
}

export function getMillisecondsInHMS({ duration }) {
  const seconds = parseInt((duration / 1000) % 60);
  const minutes = parseInt((duration / (1000 * 60)) % 60);
  const hours = parseInt((duration / (1000 * 60 * 60)) % 24);
  const days = parseInt(duration / (1000 * 60 * 60 * 24));

  if (duration <= 1000) {
    return Math.trunc(duration / 1000) + "s";
  }

  let p = "";

  if (days > 0) {
    p = p + padStart(days, 2, 0) + "d ";
  }

  if (hours > 0) {
    p = p + padStart(hours, 2, 0) + "h ";
  }
  if (hours > 0 || minutes > 0) {
    p = p + padStart(minutes, 2, 0) + "m ";
  }

  let value = (p + padStart(seconds, 2, 0) + "s").replace(/^0+/, "");

  if (value === "s") {
    value = "0s";
  }

  return value;
}

/**
 *
 * @param {date} date [required]
 * @param {boolean} start
 * @returns {string}
 */
export function getStartOREndOfDateToString({ date, start = true, timezone }) {
  if (start) {
    return DateTime.fromISO(new Date(date).toISOString()).setZone(timezone).startOf("day").toUTC().toISO().toString();
  }
  return DateTime.fromISO(new Date(date).toISOString()).setZone(timezone).endOf("day").toUTC().toISO().toString();
}
